@import "styles/core.scss";
.container {
  @include userSelectNone;
  @include flex-row;
  box-sizing: border-box;
  height: 48px;
  width: max-content;
  max-width: 100%;
  padding: var(--space-50);
  border-radius: var(--radius-round);
  background-color: var(--color-background-secondary);

  &.full {
    width: 100%;
    justify-content: space-between;

    .option {
      flex: 1; // if this is applied when not full width, the text can unnecessarily wrap
    }
  }

  &.transparent {
    background-color: transparent;

    .option.active {
      background-color: var(--color-background-secondary);
    }
  }
}

.tooltipContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.option {
  @include removeDefaultButtonStyles;
  @include text-100-bold;
  border-radius: var(--radius-round);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text-secondary);
  padding: var(--space-50) var(--space-200);
  overflow: hidden;

  &.icon {
    padding: var(--space-50) var(--space-100);
  }

  &.active {
    background-color: var(--color-background-primary);
    color: var(--color-text-primary);
  }

  &:not(.active) {
    cursor: pointer;
    @include hover {
      color: var(--color-text-secondary-hover);
    }
  }

  span {
    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &:focus-visible {
    outline: none;
    z-index: 1; // Ensure the focus shadow doesn't get cut off by other buttons
    box-shadow: inset 0 0 0 var(--borders-weight-lg) var(--color-border-focus-default);
  }
}
