@import "styles/core.scss";
.tooltipContainer {
  overflow: hidden;

  .tooltipPopper {
    @include text-100;
    color: var(--color-text-primary-inverse);
  }
}

.badge {
  overflow: hidden;

  &.clickable {
    cursor: pointer;
  }
}
