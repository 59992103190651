@import "styles/core.scss";
.button {
  @include removeDefaultButtonStyles;
}

.container {
  @include text-100;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;

  svg {
    flex-shrink: 0;
  }
}

.sm {
  @include text-50-bold;
  border-radius: var(--radius-sm);
  gap: var(--space-50);
  height: 21px; // copied from Figma
  padding: 2px 6px;
}

.dot {
  display: inline;
  width: 8px;
  height: 8px;
  border-radius: var(--radius-round);
  flex-shrink: 0;
}

.textContent {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.caution {
  color: var(--color-text-caution);
  background: var(--color-background-caution-weak);

  .dot {
    background: var(--color-background-caution-base);
  }

  &.strong {
    background: var(--color-text-caution);
  }
}

.info {
  color: var(--color-blue-700);
  background: var(--color-background-info-weak);

  .dot {
    background: var(--color-background-info-base);
  }

  &.strong {
    background: var(--color-blue-700);
  }
}

.inverse {
  color: var(--color-text-primary-inverse);
  background: var(--color-background-inverse);

  .dot {
    background: var(--color-background-secondary);
  }
}

.negative {
  color: var(--color-text-error);
  background: var(--color-background-error-weak);

  .dot {
    background: var(--color-background-error-base);
  }

  &.strong {
    font-weight: var(--font-weight-bold);
    background: var(--color-background-error-base);
  }
}

.neutral {
  color: var(--color-text-secondary);
  background: var(--color-background-secondary);

  .dot {
    background: var(--color-text-secondary);
  }

  &.strong {
    background: var(--color-text-secondary);
  }
}

.positive {
  color: var(--color-green-700);
  background: var(--color-background-success-weak);

  .dot {
    background: var(--color-background-success-base);
  }

  &.strong {
    background: var(--color-green-700);
  }
}

.strong {
  color: var(--color-text-primary-inverse);
}

.md {
  border-radius: var(--radius-md);
  gap: var(--space-100);
  height: var(--controls-size-sm);
  padding: var(--space-100) var(--space-150);
}

.minimal {
  background: none;
  padding: 0;
}
