@import "styles/core.scss";
.container {
  @include flex-column;
  padding: var(--space-300) 0;
}

.slugPreview {
  @include flex-column;
  gap: var(--space-100);
}

.slugPreview:last-child {
  margin-top: var(--space-450);
}
