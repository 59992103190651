@import "styles/core.scss";
.heatmapPrivacyModal {
  @include flex-column;
  gap: var(--space-200);
  padding: 0 var(--space-450) var(--space-450);
}

.heatmapPrivacyModalText {
  a {
    color: var(--color-text-primary);
    text-decoration: underline;
  }
}

.visuallyHidden {
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
}
