@import "styles/core.scss";
.container {
  @include flex-row;
  align-items: center;
}

.mobileContainer {
  @include flex-column;
}

.adminLinks {
  @include text-100-bold;
  background-color: inherit;
  cursor: pointer;
  border: none;
  outline: none;
  margin-right: var(--space-300);
}

.mobileLink {
  padding: var(--space-100);
}

.buttons {
  @include flex-row;
  gap: var(--space-100);
}

.mobileButtons {
  @include flex-column;
}

.addAlertButton.addAlertButton {
  margin-top: var(--space-200);
}
