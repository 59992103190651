@import "styles/core.scss";
.background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1003;
}

.layerMenuModal {
  @include flex-column;
  position: absolute;
  overflow: hidden;
  background-color: var(--color-background-primary);
  border-radius: var(--radius-xl);
  max-width: calc(100% - 2 * var(--space-48));
  max-height: 80dvh;
  width: 360px;
  z-index: 1000;
  box-shadow: var(--shadow-300);
}

.mobileMenuModal {
  height: 77dvh;
}

.mobilePrivacyContent {
  padding-top: var(--space-450);
  padding-bottom: var(--space-450);
}

.visuallyHidden {
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
}

.header {
  @include flex-column;
  width: 100%;
  padding: var(--space-300) var(--space-200) var(--space-150);
  border-bottom: var(--borders-weight-sm) solid transparent;
  transition: border-color 0.2s ease-out;
  align-items: center;
  height: 84px;

  &.bottomBorder {
    border-color: var(--color-border-separator);
  }
}

.scrollContainer {
  flex: 1;
  overflow: auto;
  @include removeScrollBar;
}

.modalContent {
  padding: var(--space-150) var(--space-200) var(--space-200);

  @include page-width-sm-down {
    padding-top: 0;
  }
}

.segmentedControl {
  @include page-width-sm-down {
    margin: var(--space-100) 0;
  }
}

.cardsContent {
  @include flex-column;
  gap: var(--space-300);
  align-items: center;

  @include page-width-sm-down {
    gap: 0;
  }
}

.cardsSection {
  @include flex-column;
  width: 100%;
  gap: var(--space-150);

  @include page-width-sm-down {
    &:not(.conditionsCards) {
      margin-top: var(--space-200);
      margin-bottom: var(--space-200);
    }
  }
}

.extraCardsSection {
  gap: var(--space-200);
}

.adminExtraCardsSection {
  gap: 0;
}

.overlayCardsSection {
  @include flex-column;
  width: 100%;
  gap: var(--space-300);

  @include page-width-sm-down {
    margin-top: var(--space-300);
    margin-bottom: var(--space-200);
  }
}

.conditionsSection {
  @include page-width-sm-down {
    margin-top: 0;
  }
}

.overlayCardContent {
  @include flex-column;
}

.footer {
  width: 100%;
  padding: var(--space-24) var(--space-32);

  @include page-width-sm-down {
    padding: var(--space-24);
  }

  border-top: var(--borders-weight-sm) solid transparent;
  transition: border-color 0.2s ease-out;

  &.topBorder {
    border-color: var(--color-border-separator);
  }
}

.heatmapTitle {
  @include flex-row;
  margin-bottom: var(--space-150);
  align-items: center;
}

.heatmapPrivacyContent {
  @include flex-column;
  gap: var(--space-200);
}
